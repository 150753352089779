body {
  overflow-x: hidden;
}

.bg-light {
  background-color: rgb(242.075, 244.19, 249.125) !important;
}

.pb_icon-gradient:before {
  background: #06B9BA; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, #06B9BA 0%, #23F4BD 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pb_slant-light,
.pb_slant-white {
  position: relative;
}
.pb_slant-light:after,
.pb_slant-white:after {
  content: "";
  position: absolute;
  bottom: -100px;
  left: -50px;
  height: 200px;
  width: 130%;
  background-color: rgb(242.075, 244.19, 249.125);
  transform: rotate(-4deg);
  z-index: 10;
}
.pb_slant-light.pb_slant-reverse:after,
.pb_slant-white.pb_slant-reverse:after {
  transform: rotate(4deg);
  left: -100px;
  bottom: -140px;
}

.pb_slant-white:after {
  background-color: #fff;
}

.pb_section {
  padding: 10em 0;
}

.pb_cover_v3 a, .pb_xl_py_cover a {
  border-bottom: 2px solid transparent;
}
.pb_cover_v3 a:hover, .pb_xl_py_cover a:hover {
  color: rgb(255, 255, 255);
}
.pb_cover_v3 .btn, .pb_xl_py_cover .btn {
  color: rgb(255, 255, 255);
}